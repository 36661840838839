@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  height: calc(100vh - 30em);
  max-height: 100vw;
  width: 90%;
}

.card {
  min-height: 100vh;
  min-width: 40em;
  max-width: 40em;
  margin: auto;
}

.sr-only {
  display: none;
}

a {
  text-decoration: none;
}

.avatar {
  max-height: 3em;
}

h1 {
  text-align: center;
}

@media print {
  .config {
    display: none;
  }
}

svg {
  stroke: black;
}
