@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@700&display=swap');

.leaflet-container {
  height: calc(100vh - 30em);
  max-height: 100vw;
  width: 90%;
}

.card {
  min-height: 100vh;
  min-width: 40em;
  max-width: 40em;
  margin: auto;
}

.sr-only {
  display: none;
}

a {
  text-decoration: none;
}

.avatar {
  max-height: 3em;
}

h1 {
  text-align: center;
}

@media print {
  .config {
    display: none;
  }
}

svg {
  stroke: black;
}